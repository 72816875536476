import { render, staticRenderFns } from "./PDFcreate_B.vue?vue&type=template&id=a542db8a&scoped=true"
import script from "./PDFcreate_B.vue?vue&type=script&lang=js"
export * from "./PDFcreate_B.vue?vue&type=script&lang=js"
import style0 from "./PDFcreate_B.vue?vue&type=style&index=0&id=a542db8a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a542db8a",
  null
  
)

export default component.exports