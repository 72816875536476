<template>
    <div>
       



        <img id="ipr2" src="../../assets/HeaderB.png">

        <div class="F1">
            <img class="F1Img" src="../../assets/logobarra.png">
            <div class="F1B2">
                <p class="F1B2_P1">Laboratorio Barragán S.A. de C.V.</p>
                <p class="F1B2_P2">Garmendia No. 80 Colonia San Benito C.P. 83190. Hermosillo, Sonora.</p>
                <p class="F1B2_P3">TEL. (662) 213 23 02, (662) 313 06 50 | barragan@laason.mx</p>
            </div>
            <div class="F1B3">
                <img src="../../assets/Logo2_Barragan.png">
                <p class="F1B3_P1">Sistema de Gestión de Calidad</p>
                <p class="F1B3_P2">Certificado ISO 9001:2015</p>
                <p class="F1B3_P3">No. de Certificado C2021-04405</p>
            </div>
        </div>

        <div id="dataCli" class="F2">
            <div class="F2B1">
                <div class="F2B1_F1">
                    <p>PACIENTE</p>
                </div>
                <div class="F2B1_F2">
                    <p>{{servicios.nombre}}</p>
                </div>
                <div class="F2B1_F3">
                    <div class="F2B1_F3B1">
                        <p class="F2B1_F3B1P1">SEXO</p>
                        <p v-if="servicios.sexo=='MAS'" class="F2B1_F3B1P2">MASCULINO</p>
                        <p v-else-if="servicios.sexo=='FEM'" class="F2B1_F3B1P2">FEMENINO</p>
                        <p v-else class="F2B1_F3B1P2">{{servicios.sexo}}</p>
                    </div>
                    <div class="F2B1_F3B2">
                        <p class="F2B1_F3B2P1">EDAD</p>
                        <p class="F2B1_F3B2P2">{{servicios.edad}} AÑOS</p>
                    </div>
                    <div class="F2B1_F3B3">
                        <p class="F2B1_F3B3P1">FECHA DE NACIMIENTO</p>
                        <p class="F2B1_F3B3P2">{{servicios.fecha_nacimiento}}</p>
                    </div>
                </div>
            </div>
            <div class="F2B2">
                <div class="F2B2_F1">
                    <p>{{servicios.clave}}</p>
                </div>
                <div class="F2B2_F2">
                    <div class="F2B2_F2B1">
                        <p>FECHA DE RECEPCIÓN</p>
                    </div>
                    <div class="F2B2_F2B2">
                        <p>{{PDFinfo.servicio.fecha}}</p>
                    </div>
                </div>
                <div class="F2B2_F3">
                    <div class="F2B2_F3B1">
                        <p>FECHA DE EMISIÓN</p>
                    </div>
                    <div class="F2B2_F3B2">
                        <p>{{getDate(servicios)}}</p>
                    </div>
                </div>
                <div class="F2B2_F4">
                    <p id="maxPages">Hoja 1 de {{MP}}</p>
                </div>
            </div>
        </div>

        <div id="pdfdoc" ref='content'>


        


        

            
<!-- :style="itemP.salto == 'margin-bottom' ? {'margin-bottom': itemP.nuevoDato + 'vw'} : {'margin-top': itemP.nuevoDato + 'vw'}" -->
            
            <div class="F4" v-for="(item, key) in servicios.muestras" :key="key">

                <div v-if="PDFinfo.id == item.id">
                <div :id="'F4Com'+key" class="F3" :style="item.extra.salto == 'comentario' ? {'margin-top': item.extra.nuevoDato + 'vw'} : {'margin-top': 0 + 'vw'}">
                        
                    <div class="F3F1">
                        <p class="F3F1_B1">OBSERVACIONES</p>
                        <p class="F3F1_B2">{{item.extra.obv}}</p>
                    </div>
                    <div class="F3F2"></div>
                </div>

                <div :id="'F4Elem'+key+'-'+keyP" class="F4F3" v-for="(itemP, keyP) in item.perfiles" :key="keyP">
                    

                    

                    <div class="F4F1" :style="itemP.salto == 'prueba' ? {'margin-top': itemP.nuevoDato + 'vw'} : {'margin-top': 0 + 'vw'}">
                        <p class="F4F1_P1">ANÁLISIS:</p>
                        <p class="F4F1_P2">{{itemP.name}}</p>
                    </div>
                    <div class="F4F2">
                        <p class="F4F2_P1">PRUEBA</p>
                        <p class="F4F2_P2">RESULTADO</p>
                        <p class="F4F2_P3">UNIDADES</p>
                        <p class="F4F2_P4">REFERENCIA</p>
                    </div>
                    
                    <div>
                        <div :id="'F4ElemContent'+key+'-'+keyP+keyRes" :class="{F4F3_BR2: keyRes < (itemP.resultados.length - 1) }" class="F4F3_BR" v-for="(itemRes, keyRes) in itemP.resultados" :key="keyRes" >
                            
                            <p class="F4F3_B1">{{itemRes.pname}}</p>
                            <p class="F4F3_B2">{{itemRes.resultado}}</p>
                            <p class="F4F3_B3">{{itemRes.uni}}</p>
                            <p :id="'pref'+keyRes" :class="{centerref: styleforref(itemRes.pref)}" class="F4F3_B4" v-html="itemRes.pref"></p>
                            
                        </div>
                        <!--<img v-if="keyRes < (itemP.resultados.length - 1)" class="ImgSepa" src="../../assets/Separador.svg">-->

                    </div>
                    <div class="F4F4">
                        <!--<div class="F4F4_F1">
                            <p><span>**TRH:</span> Terapia de Reemplazo Hormonal</p>
                        </div>-->
                        <div class="F4F4_F2">
                            <p>Método: {{itemP.metodo}}</p>
                        </div>
                        <div class="F4F4_F3">
                            <div class="F4F4_F3B1">
                                <p>Tipo de Muestra: {{itemP.tipo_muestra}}</p>
                            </div>
                            <div class="F4F4_F3B2">
                                <p>Toma de Muestra: {{TomaMuestra(servicios.fecha)}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                
                </div>

            </div>

            

        </div>

        <div class="F7">
            <img class="F7I1" src="../../assets/QR_Barragan.png">
            <img class="F7I2" src="../../assets/Firma_Barragan.png">
            <p>Hoja 1 de 4</p>
        </div>

        <div class="F12">
            <p @click="wait()" class="btnRegresar5">Regresar</p>  <button class="DButton" @click="downloadWithCSS(false, 1)">Descargar PDF</button> 
            
            <button v-if="identity.role !='cliente'" class="DButton DSL" @click="downloadWithCSS(true, 0)">Descargar PDF Sin Logo</button> 
            <form @submit.prevent="submit(file1, muestra)">
                <div v-if="identity.role !='cliente'" class="enviar">
                    <label>Archivo
                        <input type="file" id="file1" ref="file1" v-on:change="handleFileUpload()"/>
                    </label>
                    <button class="DButton" @click="submit">Enviar PDF</button>
                </div>

            </form>
        
        </div>

        <img id="ipr" src="../../assets/PDFooter_B.png">

    </div>
</template>

<script>

//import html2PDF from "jspdf-html2canvas";
import html2PDF from "html2pdf.js";

import jspdf  from "jspdf";
import {mapActions} from 'vuex';
import userService from '../../services/user.service'
import axios from "axios";


export default {
    data() {
        return {
            servicios:'',
            file1: null,
            status:'',
            message:'',
            msg:"",
            MP: 1,
        }
    },
    async created() {

        //let palabra = 'Observaciones de U1 Observaciones de U1 Observaciones de U1 Observaciones de U1 Observaciones de U1 Observaciones de U1 '
        
        let payload ={
            option:'servicios_b',
            id:this.PDFinfo.id_serv
        }
        this.servicios = await this.getInfoByIdSrvB(payload)

        let contador=0, NLO=3, FP=false;

        
        
        
        
    },
    computed:{

        style() {

            return {
                margin: '5vw'
            }
        },
        
        muestra(){
            return this.$store.getters["muestras/getAdded"]
        },
        PDFinfo(){
            let array = this.$store.getters["PDF/getAdded"]
            
            return this.$store.getters["PDF/getAdded"]
        },
        identity() {
            return this.$store.getters['admin/getIdentity'];
        },
    },
    methods:{
        ...mapActions('main', ['addItem']),
        ...mapActions("texto", ["getAllInfoTxt"]),
        ...mapActions('servicios_b', ['getInfoByIdSrvB']),

        
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },

        styleforref: function(item) {
            
            if(item.split('</p>').length > 1){
                
                return true;
            }else {
                return false;
            }
        },

        linejump: function(text, idK){
            
            
            
            let var2=text.split(',').join('<br />');
            var2=var2.split(':').join(':<br />');
            
            
            return var2;
        },

        handleFileUpload(){
            this.file1 = this.$refs.file1.files[0];
        },

        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },

        delMsgs: function(){
            this.status=""
            this.message=""
        },

        TomaMuestra(fulldate) {
            let date = fulldate.slice(0, 10)
            let hour = fulldate.slice(11, 16)

            date = date.split("-").join('/')

            let fechahora = date +" "+ hour

            return fechahora;
        },

        SpaceElem: function() {
            
        },

        async downloadWithCSS(HoldHeader, virtualPrint) {

            

            const pages = document.getElementById('pdfdoc');

            
            let clalason =this.PDFinfo.servicio.clave+'-'+this.TomaMuestra(this.PDFinfo.servicio.fecha)
            
            var options = {
                quality: .99,
                width: 2000,
                height: 2000,
            };

            let sheight=screen.height * 1.5,swidth=screen.width * 1.3;
            

            var opt

            //if(swidth>1600){
                opt = {
                    margin:       [(sheight * 30) / 100,30,(sheight * 20) / 100,30],
                    filename:     `Informe de prueba ${clalason}.pdf`,
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2 },
                    jsPDF:        { unit: 'px', format: [swidth, sheight+60], orientation: 'portrait' },//'mm', 'a2'
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                };
            /*}else{
                opt = {
                    margin:       [110,0,50,0],
                    filename:     `Informe de prueba ${clalason}.pdf`,
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2 },
                    jsPDF:        { unit: 'mm', format: 'b3', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                };
            }*/

            // New Promise-based usage:
            html2PDF().set(opt).from(pages).toPdf().get('pdf').then(async function (pdf) {

                var totalPages = pdf.internal.getNumberOfPages();
let algo = []

    for (let i = 1; i <= totalPages; i++) {
        document.getElementById("maxPages").innerHTML="Hoja "+i+" de "+totalPages
        async function runIMGDOM(){
                    
                    return domtoimage.toPng(document.getElementById('dataCli'), options)
                    .then(function(dataUrl) {
                        var img = new Image();
                        img.src = dataUrl;
                        return img;
                    })

                    .catch(function(error) {
                        console.error("oops, something went wrong!", error);
                    });
                }
                algo.push(await runIMGDOM())
    }
                

                

                function getBase64Image(img) {
                    var canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);
                    var dataURL = canvas.toDataURL();
                    return dataURL;
                }

                
                var base64 = getBase64Image(document.getElementById("ipr"));
                
                var base64_2 = getBase64Image(document.getElementById("ipr2"));

                for (let i = 1; i <= totalPages; i++) {
                    
                    
                    var algodon = getBase64Image(algo[i-1]);
                //if(swidth>1600){
                    
                    /*pdf.setPage(i);
                    if(!HoldHeader){
                        pdf.addImage(base64_2, 'PNG', 2, 5, 400, 55, { baseline: 'top' }, 'SLOW');
                        pdf.addImage(base64, 'PNG', 2, 550, 245, 40, { baseline: 'bottom' }, 'SLOW');
                    }
                    pdf.addImage(algodon, 'PNG', 2, 75, 415, 550, { baseline: 'top' }, 'SLOW');
                    pdf.setFontSize(15)
                    pdf.setTextColor(86,86,86);
                    pdf.text('Hoja ' + i + ' ' +'de ' + totalPages, 335, 122);*/
                    pdf.setPage(i);
                    if(!HoldHeader){
                        pdf.addImage(base64_2, 'PNG', 32, 5, (swidth * 63) / 100, (sheight * 13) / 100, { baseline: 'top' }, 'SLOW');
                        pdf.addImage(base64, 'PNG', 32, (sheight * 1.4), (swidth * 35) / 100, (sheight * 10) / 100, { baseline: 'bottom' }, 'SLOW');
                    }
                    pdf.addImage(algodon, 'PNG', 32, (sheight * 20) / 100, (swidth * 63) / 100, (sheight * 90) / 100, { baseline: 'top' }, 'SLOW');
                    pdf.setFontSize((swidth * 0.9) / 100)
                    pdf.setTextColor(86,86,86);
                    //pdf.text('Hoja ' + i + ' ' +'de ' + totalPages, (swidth * 48) / 100, (sheight * 18.5) / 100);

                /*}else{

                    pdf.setPage(i);
                    if(!HoldHeader){
                        pdf.addImage(base64_2, 'PNG', 2, 5, 325, 45, { baseline: 'top' }, 'SLOW');
                        pdf.addImage(base64, 'PNG', 2, 450, 225, 40, { baseline: 'bottom' }, 'SLOW');
                    }
                    pdf.addImage(algodon, 'PNG', 2, 65, 330, 500, { baseline: 'top' }, 'SLOW');
                    pdf.setFontSize(12)
                    pdf.setTextColor(86,86,86);
                    pdf.text('Hoja ' + i + ' ' +'de ' + totalPages, 280, 99);

                }*/
 
                
                }
            }).save();


            
        },
            submit: async function ( file1, muestra ) {
                this.status='';
                this.message='';
                
                /*var pdfson = doc.output();
                var data = new FormData();
                data.append("data", pdfson);
                var xhr = new XMLHttpRequest();
                xhr.open('post', '/send/create', true);
                xhr.send(data);*/
                

                //document.body.appendChild(img);
                
                try {
                    var extencion = this.$refs.file1.files[0].name;
                    var extencionF = extencion.substring(extencion.length - 3, extencion.length);
                    var correo = this.PDFinfo.servicio.cliente.email;
                    var name = this.PDFinfo.servicio.cliente.name
                    var clave = this.PDFinfo.servicio.clave
                    var idmuestra = this.PDFinfo.id
                }catch(e) {
                    this.message='Correo no enviado, ingresar archivo';
                    this.status='error';
                }
                
                
                if(file1 == null || file1 == undefined || file1 == ''){
                    this.message='Correo no enviado, ingresar archivo';
                    this.status='error';
                }else if(extencionF != 'pdf'){
                    this.message='Correo no enviado, extension no valida';
                    this.status='error';
                    this.delStatus()
                }else{

                    let token = userService.getToken() 
                    let  headers = {
                        Authorization: token
                    }


                    var data = new FormData();
                
                    data.append('image', file1);
                    data.append('_method', 'POST');
                    data.append('info', muestra);
                    data.append('mail', correo);
                    data.append('name', name)
                    data.append('clave',clave)
                    data.append('idmuestra', idmuestra)

                    //let result = this.$store.dispatch("main/addItem",  {option:'send', item: data});
                    let result = axios.post(`/send/create`, data, {headers});
                    this.message='Correo enviado exitosamente';
                    this.status='success';
                    this.delStatus()

                }



            },
            getDate(servicios){
                if(servicios.muestras[0].fecha_f){
                   return  servicios.muestras[0].fecha_f.slice(0, 10);
                }

                return servicios.updated_at.slice(0, 10);
            }



    }
}
</script>

<style scoped>


.centerref {
    text-align: inherit !important;
}

.F1 {
    display: flex;
    width: 82.51633986928104VW;
    justify-content: space-between;
    margin-bottom: 4.493464052287582VW;
}
.F1B2 {
    height: 10.53921568627451VW;
    border-right: 0.2vw solid #792E18;
    padding-right: 6.699346405228758VW;
    margin-right: 6.535947712418301VW;
    padding-top: 1.5vw;
}
    img.F1Img {
        width: 11.11111111111111VW;
        height: auto;
    }
    p.F1B2_P1 {
        font-size: 1.9607843137254901VW;
        font-weight: 600;
        color: #792E18;
        margin: 0vw;
        font-family: var(--Open_Sans);
    }
    p.F1B2_P2 {
        font-size: 1.3071895424836601VW;
        font-weight: 400;
        font-family: var(--Open_Sans);
        margin: 0vw;
        color: var(--color-4);
    }
    p.F1B2_P3 {
        font-size: 1.3071895424836601VW;
        font-weight: 600;
        font-family: var(--Open_Sans);
        margin: 0vw;
        color: var(--color-4);
    }


.F1B3 {
    text-align: center;
}
    .F1B3 img {
        width: 10.130718954248366VW;
        height: auto;
        margin-bottom: 0.8169934640522877VW;
    }
    p.F1B3_P1 {
        font-size: .87VW;
        font-family: var(--Open_Sans);
        font-weight: 600;
        margin: 0vw;
    }
    p.F1B3_P2 {
        font-size: 1.13VW;
        font-family: var(--Open_Sans);
        font-weight: 600;
        margin: 0vw;
    }
    p.F1B3_P3 {
        font-size: .87VW;
        font-family: var(--Open_Sans);
        font-weight: 600;
        margin: 0vw;
    }


.F2 {
    display: flex;
    width: 82.51633986928104VW;
    justify-content: space-between;
    margin-bottom: 1.4705882352941175VW;
}
.F2B1 {
    width: 46.07843137254902VW;
}
.F2B1_F1 {
    display: flex;
    width: 46.07843137254902VW;
    height: 2.287581699346405VW;
    background-color: var(--color-9);
    align-items: center;
    justify-content: center;
    border-radius: 9.803921568627452VW 0vw 0vw 0vw;
    margin-bottom: 0.16339869281045752VW;
}
    .F2B1_F1 p {
        font-size: 0.9803921568627451VW;
        font-weight: 700;
        font-family: var(--Open_Sans);
        color: var(--color-5);
        margin: 0vw;
    }
.F2B1_F2 {
    display: flex;
    width: 46.07843137254902VW;
    height: 2.287581699346405VW;
    background-color: var(--color-10);
    align-items: center;
    justify-content: center;
    margin-bottom: 0.16339869281045752VW;
}
    .F2B1_F2 p {
        font-size: 0.9803921568627451VW;
        font-weight: 700;
        font-family: var(--Open_Sans);
        color: #565656;
        margin: 0vw;
        letter-spacing: 0.001vw;
        white-space: nowrap;
    }

.F2B1_F3 {
    display: flex;
    justify-content: space-between;
}
    .F2B1_F3B1 {
        width: 15.032679738562091VW;
    }
        p.F2B1_F3B1P1 {
            display: flex;
            width: 15.032679738562091VW;
            height: 2.287581699346405VW;
            background-color: var(--color-9);
            color: var(--color-5);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            align-items: center;
            justify-content: center;
            margin: 0vw;
            margin-bottom: 0.16339869281045752VW;
        }
        p.F2B1_F3B1P2 {
            display: flex;
            width: 15.032679738562091VW;
            height: 2.287581699346405VW;
            background-color: var(--color-10);
            color: #565656;
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            align-items: center;
            justify-content: center;
            border-radius: 0VW 0vw 0vw 9.803921568627452vw;
            margin: 0vw;
        }
    
    .F2B1_F3B2 {
        width: 12.908496732026146VW;
    }
        p.F2B1_F3B2P1 {
            display: flex;
            width: 12.908496732026146VW;
            height: 2.287581699346405VW;
            background-color: var(--color-9);
            color: var(--color-5);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            align-items: center;
            justify-content: center;
            margin: 0vw;
            margin-bottom: 0.16339869281045752VW;
        }
        p.F2B1_F3B2P2 {
            display: flex;
            width: 12.908496732026146VW;
            height: 2.287581699346405VW;
            background-color: var(--color-10);
            color: #565656;
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            align-items: center;
            justify-content: center;
            margin: 0vw;
        }
    
    .F2B1_F3B3 {
        width: 17.81045751633987VW;
    }
        p.F2B1_F3B3P1 {
            display: flex;
            width: 17.81045751633987VW;
            height: 2.287581699346405VW;
            background-color: var(--color-9);
            color: var(--color-5);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            align-items: center;
            justify-content: center;
            margin: 0vw;
            margin-bottom: 0.16339869281045752VW;
        }
        p.F2B1_F3B3P2 {
            display: flex;
            width: 17.81045751633987VW;
            height: 2.287581699346405VW;
            background-color: var(--color-10);
            color: #565656;
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            align-items: center;
            justify-content: center;
            margin: 0vw;
        }

.F2B2 {
    width: 34.150326797385624VW;
}
    .F2B2_F1 {
        display: flex;
        width: 34.150326797385624VW;
        height: 2.287581699346405VW;
        align-items: center;
        justify-content: center;
        background-color: var(--color-8);
        border-radius: 0VW 9.803921568627452vw 0vw 0vw;
        margin-bottom: 0.16339869281045752VW;
    }
        .F2B2_F1 p {
            font-size: 1.3071895424836601VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            color: var(--color-5);
            margin: 0vw;
        }

    .F2B2_F2 {
        display: flex;
        width: 34.150326797385624VW;
        justify-content: space-between;
        margin-bottom: 0.16339869281045752VW;
    }
        .F2B2_F2B1 {
            display: flex;
            width: 16.99346405228758VW;
            height: 2.287581699346405VW;
            background-color: var(--color-8);
            align-items: center;
            justify-content: center;
        }
            .F2B2_F2B1 p {
                font-size: 0.9803921568627451VW;
                font-weight: 700;
                font-family: var(--Open_Sans);
                color: var(--color-5);
                margin: 0vw;
                white-space: nowrap;
            }
        .F2B2_F2B2 {
            display: flex;
            width: 16.99346405228758VW;
            height: 2.287581699346405VW;
            background-color: var(--color-10);
            align-items: center;
            justify-content: center;
        }
            .F2B2_F2B2 p {
                font-size: 1.1437908496732025VW;
                font-weight: 700;
                font-family: var(--Open_Sans);
                color: var(--color-4);
                margin: 0vw;
                white-space: nowrap;
            }

    
    .F2B2_F3 {
        display: flex;
        width: 34.150326797385624VW;
        margin-bottom: 0.16339869281045752VW;
    }
    .F2B2_F3B1 {
        display: flex;
        width: 16.99346405228758VW;
        height: 2.287581699346405VW;
        background-color: var(--color-8);
        align-items: center;
        justify-content: center;
    }
        .F2B2_F3B1 p {
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            color: var(--color-5);
            margin: 0vw;
            white-space: nowrap;
        }
    .F2B2_F3B2 {
        display: flex;
        width: 16.99346405228758VW;
        height: 2.287581699346405VW;
        background-color: var(--color-10);
        align-items: center;
        justify-content: center;
    }
        .F2B2_F3B2 p {
            font-size: 1.1437908496732025VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            color: var(--color-4);
            margin: 0vw;
            white-space: nowrap;
        }
    .F2B2_F4 {
        display: flex;
        width: 34.150326797385624VW;
        height: 2.287581699346405VW;
        align-items: center;
        justify-content: center;
        background-color: var(--color-10);
        border-radius: 0VW 0vw 9.803921568627452vw 0vw;
    }
        .F2B2_F4 p {
            font-size: 1.1437908496732025VW;
            font-weight: 400;
            font-family: var(--Open_Sans);
            color: var(--color-4);
            margin: 0vw;
            white-space: nowrap;
        }



.F3 {
    width: 82.51633986928104VW;
    margin-bottom: 1.3071895424836601VW;
    background-color: var(--color-10);
    border-radius: 8.803922VW;
}
    .F3F1 {
        display: flex;
        width: 82.51633986928104VW;
        justify-content: space-between;
    }
        p.F3F1_B1 {
            width: 15.032679738562091VW;
            height: 2.287581699346405VW;
            margin: 0vw;
            background-color: var(--color-9);
            color: var(--color-5);
            border-radius: 9.803921568627452VW 0vw 0vw 0vw;
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            text-align: center;
            padding-top: 0.45vw;
        }
        p.F3F1_B2 {
            width: 67.3202614379085VW;
            min-height: 2.287581699346405VW;
            margin: 0vw;
            background-color: var(--color-10);
            color: var(--color-4);
            border-radius: 0VW 9.803921568627452vw 0vw 0vw;
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            padding-top: 0.45vw;
            padding-left: 2.1241830065359477VW;
        }
    .F3F2 {
        width: 82.51633986928104VW;
        height: 2.287581699346405VW;
        background-color: var(--color-10);
        border-radius: 0VW 0vw 9.803921568627452vw 9.803921568627452vw;
        margin-top: 0.16339869281045752VW;
    }


.F4 {
    width: 82.51633986928104VW;
    margin-bottom: 1.3071895424836601VW;
}
    .F4F1 {
        display: flex;
        justify-content: space-between;
    }
        p.F4F1_P1 {
            width: 8.660130718954248VW;
            height: 2.287581699346405VW;
            margin: 0vw;
            background-color: var(--color-9);
            color: var(--color-5);
            border-radius: 9.803921568627452VW 0vw 0vw 0vw;
            font-size: 0.9803921568627451VW;
            font-weight: 400;
            font-family: var(--Open_Sans);
            text-align: center;
            padding-top: 0.45vw;
        }
        p.F4F1_P2 {
            width: 73.69281045751634VW;
            height: 2.287581699346405VW;
            margin: 0vw;
            background-color: var(--color-9);
            color: var(--color-5);
            border-radius: 0VW 9.803921568627452vw 0vw 0vw;
            font-size: 0.9803921568627451VW;
            font-weight: 400;
            font-family: var(--Open_Sans);
            padding-top: 0.45vw;
            padding-left: 2.1241830065359477VW;
        }
    
    .F4F2 {
        display: flex;
        justify-content: space-between;
    }
        p.F4F2_P1 {
            display: flex;
            width: 33.98692810457516VW;
            height: 1.3071895424836601VW;
            background-color: var(--color-10);
            color: var(--color-9);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            margin: 0vw;
            align-items: center;
            padding-left: 2.1241830065359477VW;
        }
        p.F4F2_P2 {
            display: flex;
            width: 13.23529411764706VW;
            height: 1.3071895424836601VW;
            background-color: var(--color-10);
            color: var(--color-9);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            margin: 0vw;
            align-items: center;
            justify-content: center;
        }
        p.F4F2_P3 {
            display: flex;
            width: 13.23529411764706VW;
            height: 1.3071895424836601VW;
            background-color: var(--color-10);
            color: var(--color-9);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            margin: 0vw;
            align-items: center;
            justify-content: center;
        }
        p.F4F2_P4 {
            display: flex;
            width: 21.568627450980394VW;
            height: 1.3071895424836601VW;
            background-color: var(--color-10);
            color: var(--color-9);
            font-size: 0.9803921568627451VW;
            font-weight: 700;
            font-family: var(--Open_Sans);
            margin: 0vw;
            align-items: center;
            justify-content: center;
        }

    .F4F3 {
        margin-bottom: 1.3071895424836601VW;
    }
        p.F4F3_B1 {
            display: flex;
            width: 33.98692810457516VW;
            min-height: 1.3071895424836601VW;
            font-size: 1.1437908496732025VW;
            font-weight: 400;
            font-family: var(--Open_Sans);
            color: var(--color-4);
            margin: 0.2vw 0vw;
            line-height: 1.5571895424836601VW;
            padding-left: 2.1241830065359477VW;
            align-items: center;
            letter-spacing: 0.001vw;
        }
        .F4F3_BR {
            display: flex;
            align-items: center;
        }
        .F4F3_BR2 {
            display: flex;
            border-bottom: 0.049019607843137254VW dashed #565656;
            align-items: center;
        }
            p.F4F3_B2 {
                display: flex;
                width: 13.23529411764706VW;
                min-height: 1.3071895424836601VW;
                font-size: 1.1437908496732025VW;
                font-weight: 700;
                font-family: var(--Open_Sans);
                color: var(--color-4);
                margin: 0vw;
                align-items: center;
                justify-content: center;
                text-align: center;
                line-height: 1.5571895424836601VW;
            }
            p.F4F3_B3 {
                display: flex;
                width: 13.23529411764706VW;
                min-height: 1.3071895424836601VW;
                font-size: 1.1437908496732025VW;
                font-weight: 700;
                font-family: var(--Open_Sans);
                color: var(--color-4);
                margin: 0vw;
                align-items: center;
                justify-content: center;
                text-align: center;
                line-height: 1.5571895424836601VW;
            }
            p.F4F3_B4 {
                /*display: flex;*/
                width: 21.568627450980394VW;
                min-height: 1.3071895424836601VW;
                font-size: 1.1437908496732025VW;
                font-weight: 700;
                font-family: var(--Open_Sans);
                color: var(--color-4);
                margin: 0vw;
                align-items: center;
                justify-content: center;
                text-align: center;
                line-height: 1.5571895424836601VW;
            }

.F4F4 {
    margin-top: 1.3vw;
    padding-left: 2.1241830065359477VW;
}
        .F4F4_F1 p {
            font-size: 0.9VW;
            font-family: var(--Open_Sans);
            font-weight: 400;
            color: var(--color-4);
            margin: 0vw;
        }
        .F4F4_F1 span {
            font-weight: 700 !important;
        }
        .F4F4_F2 p {
            font-size: 0.9VW;
            font-family: var(--Open_Sans);
            font-weight: 400;
            color: var(--color-4);
            margin: 0vw;
            letter-spacing: 0.001vw;
        }
        .F4F4_F3 {
            display: flex;
        }
            .F4F4_F3B1 p {
                font-size: 0.9VW;
                font-family: var(--Open_Sans);
                font-weight: 400;
                color: var(--color-4);
                margin: 0vw;
                letter-spacing: 0.001vw;
            }
        .F4F4_F3B2 {
            margin-left: 20.751633986928105VW;
        }
            .F4F4_F3B2 p {
                font-size: 0.9VW;
                font-family: var(--Open_Sans);
                font-weight: 400;
                color: var(--color-4);
                margin: 0vw;
            }



.F7 {
    display: flex;
    width: 82.51633986928104VW;
    align-items: end;
    justify-content: space-between;
}
    img.F7I1 {
        width: 8.169934640522875VW;
        height: 8.169934640522875VW;
    }
    img.F7I2 {
        width: 31.5359477124183VW;
        height: auto;
    }
    .F7 p {
        font-size: 1.1437908496732025VW;
        font-family: var(--Open_Sans);
        font-weight: 400;
        color: var(--color-4);
        margin: 0vw;
    }


.F12 {
    display: flex;
}

.enviar {
    margin-left: 18vw;
}
.DButton {
  cursor: pointer;
  width: 10.156vw;
  height: 2.292vw;
  border: 0px;
  margin-right: 0.072vw;
  font-size: 0.729vw;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-bottom: 1vw;
  background: #007E94;
  margin-top: 2.7083333333333335VW;
}

.DSL {
    margin-left: 1vw;
}



.enviar label {
  color: #007E94;
  font-size: 1.6vw;
}
.enviar input {
  width: 22vw;
  font-size: 1vw;
  color: rgb(121, 121, 121);
}




.enviar input::-webkit-file-upload-button {
    text-align: center;
    color: rgb(121, 121, 121);
    background-color: rgb(245, 245, 245);
    font-weight: 700;
    font-size: 0.8vw;
    padding: 0.8vw 0.5vw;
    margin-bottom: 0.03vw;
    border: 0vw;
    cursor: pointer;
}
.enviar input::before {
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.enviar input:hover::before {
  border-color: black;
}

img.ImgSepa {
    width: 82.51633986928104VW;
}



    
#ipr {
  visibility: hidden;
}
#ipr2 {
    display: none;
}

.jumpLineB {
    margin-bottom: 9vw !important;
}
.jumpLineT {
    margin-top: 9vw !important;
}

</style>
